<template>
  <div>
    <b-row v-if="!$route.params['class_name']">
      <b-col md="12" class="my-1">
        <b-card>
          <ClassNameDropdown label="Descriptor Class" :class_name.sync="class_name" @input="value => cacheClassName(value)"></ClassNameDropdown>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="has_to_relation" cols="12" lg="5" xl="4">
        <b-card :header="to_relation_title" header-class="p-2 p-sm-3" no-body>
          <div slot="header">
            <strong>{{ to_relation_title }}</strong>
            <div class="card-header-actions">
              <!-- TODO Allow editing Here -->
              <!-- <b-button-group size="sm">
                <b-button v-if="target_class_name" variant="secondary">
                  <i class="fa fa-pencil-alt"></i>
                </b-button>
              </b-button-group> -->
            </div>
          </div>
          <q-linear-progress color="blue-grey-6" indeterminate :class="{ invisible: !loading }" />
          <div class="p-2 p-sm-3">
            <div v-if="error_message" v-html="error_message" class="alert alert-danger" role="alert"></div>
            <div v-if="success_message" v-html="success_message" class="alert alert-success" role="alert"></div>
            <div v-if="to_relation">
              <div v-if="is_timestamped && timestamp_properties">
                <PropertiesView :properties="timestamp_properties" :params.sync="to_relation" :disabled="true"></PropertiesView>
                <hr />
              </div>
              <TabView
                :class_name="target_class_name"
                :params.sync="to_relation"
                :disabled="loading || true"
                :allow_columns="false"
                :with_virtual_properties="true"
                :show_virtual_properties="false"
              ></TabView>
              <!--
                :with_virtual_properties="false"
                :show_virtual_properties="true"
              -->
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" :lg="has_to_relation ? 7 : 12" :xl="has_to_relation ? 8 : 12">
        <slot>
          <!-- TODO: Wrap OrderDescriptorCard, AccountDescriptorCard, etc under DescriptorPage -->
          <DescriptorCard
            v-if="class_name"
            ref="descriptorCardRef"
            v-bind="Object.assign({}, $attrs)"
            :class_name="class_name"
            :title="index_title"
            @onModalClose="initRouteQuery"
            :fixed_params="fixed_params_"
            :_index_params="index_params"
            :_immutable="_immutable"
            :allow_relationships_filter="true"
            :allow_properties_filter="true"
            :allow_json_filter="true"
            :allow_predefined_filter="allow_predefined_filter"
            :show_relationship_actions="true"
            :allow_select_fields="true"
            :stateIdentifier="index_cache_key"
            :with_childs_mode="with_childs_mode"
            :manual_get="manual_get"
          />
        </slot>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from 'lodash';
import localForage from 'localforage';
import { QLinearProgress } from 'quasar';
import TabView from './components/modal/TabView';
import PropertiesView from './components/modal/PropertiesView';

var descriptorPageForage = localForage.createInstance({
  name: 'descriptorPageForage',
});

export default {
  components: {
    ClassNameDropdown: () => import('./components/modal/ClassNameDropdown'),
    DescriptorCard: () => import('./DescriptorCard'),
    QLinearProgress,
    TabView,
    PropertiesView,
  },
  props: {
    _immutable: {
      type: Boolean,
      default: false,
    },
    fixed_params: {
      type: Object,
    },
    _index_params: {
      type: Object,
    },
    show_relationship_actions: {
      type: Boolean,
      default: true,
      // default: false
    },
    with_childs_mode: {},
    manual_get: {},
  },
  data: () => {
    return {
      class_name_: null,

      predefined_params: {},
      predefined_filter_params: {},
      index_cache_key: null,

      has_to_relation: false,
      to_relation: null,
      target_class_name: null,
      loading: false,

      allow_predefined_filter: true,
      loaded_from_server: false,

      error_message: null,
      success_message: null,
    };
  },
  computed: {
    class_name: {
      // getter
      get: function() {
        if (this.$route.params['class_name']) {
          return this.$route.params['class_name'];
        }
        return this.class_name_;
      },
      // setter
      set: function(class_name) {
        this.class_name_ = class_name;
      },
    },
    class_() {
      return this.$d.getClass(this.class_name);
    },
    to_class() {
      return this.$d.getClass(this.target_class_name);
    },
    is_timestamped() {
      return this.to_class['timestamped'] ? true : false;
    },
    /* to_properties() {
      return this.to_class['properties'];
    }, */
    timestamp_properties() {
      return [
        {
          property_name: 'Recorded At',
          property_key: 'recorded_at',
          type: 'datetime',
        },
        /* {
          property_name: 'Archived At',
          property_key: 'archived_at',
          type: 'datetime'
        } */
      ];

      /*  let include_property_keys = [
        'recorded_at'
        // 'archived_at'
      ];
      return this.to_properties.filter(property => {
        return include_property_keys.includes(property['property_key']);
      }); */
    },
    fixed_params_() {
      let fixed_params_ = {};
      fixed_params_ = _.merge(fixed_params_, this.fixed_params);
      fixed_params_ = _.merge(fixed_params_, this.predefined_params);
      return fixed_params_; //TODO: may be flawed
    },
    index_params() {
      let index_params = {};
      index_params = _.merge(index_params, this._index_params);
      index_params = _.merge(index_params, this.predefined_filter_params);
      return index_params; //TODO: may be flawed
    },
    to_relation_title() {
      let to_relation_title = '';

      to_relation_title += this.target_class_name;

      let relationship_names = _.map(this.route_query_relationship_actions, relationship_action => {
        let relationship = relationship_action['relationship'];
        let direction = relationship_action['direction'];
        let inv_direction = this.$d.invertRelationshipDirection(direction);

        if (inv_direction == 'to') {
          return `${relationship['relationship_name']}`;
        } else {
          if (relationship['inverse_relationship_name']) {
            return `${relationship['inverse_relationship_name']}`;
          }
          return `${relationship['relationship_name']}`;
          // return `${inv_direction} ${relationship['relationship_name']}`;
        }
      });

      if (relationship_names && relationship_names.length > 0) {
        to_relation_title += ' (' + relationship_names.join(', ') + ')';
      }

      return to_relation_title;
    },
    index_title() {
      let index_title = '';

      index_title += this.class_name;

      let relationship_names = _.map(this.route_query_relationship_actions, relationship_action => {
        let relationship = relationship_action['relationship'];
        let direction = relationship_action['direction'];
        let inv_direction = this.$d.invertRelationshipDirection(direction);

        if (inv_direction == 'from') {
          return `${relationship['relationship_name']}`;
        } else {
          if (relationship['inverse_relationship_name']) {
            return `${relationship['inverse_relationship_name']}`;
          }
          return `${relationship['relationship_name']}`;
          // return `${inv_direction} ${relationship['relationship_name']}`;
        }
      });

      if (relationship_names && relationship_names.length > 0) {
        index_title += ' (' + relationship_names.join(', ') + ')';
      }

      return index_title;
    },
    route_query_relationship_actions() {
      let route_query_relationship_actions = [];

      ['to', 'from'].forEach(direction => {
        let dir_relationships = this.$d.getRelationships(this.class_name, direction);
        // console.log('direction', direction, dir_relationships);
        dir_relationships.forEach(relationship => {
          let dir_relation_alias = this.$d.getRelationAlias(relationship, direction);
          // console.log('route_query_relationship_actions', dir_relation_alias, this.$route.query[dir_relation_alias]);
          if (this.$route.query[dir_relation_alias]) {
            console.log('relationship_action found', dir_relation_alias);
            route_query_relationship_actions.push({
              relationship: relationship,
              direction: direction,
            });
          }
        });
      });

      return route_query_relationship_actions;
    },
  },
  watch: {
    '$route.query'(to, from) {
      // this.initRouteQuery();
      console.log('$route.query changedd');
      this.init();
    },
    class_name(to, from) {
      this.$emit('onClassName', to);
    },
  },
  created() {
    this._ = _;
    this.init();
  },
  methods: {
    init() {
      // console.log('current class_name:', this.class_name);

      this.predefined_params = {};
      this.predefined_filter_params = {};
      this.index_cache_key = null;
      this.has_to_relation = false;
      this.to_relation = null;
      this.target_class_name = null;
      this.loading = false;
      this.allow_predefined_filter = true;
      this.loaded_from_server = false;

      if (!this.class_name && this.$d.isInit) {
        // console.log('get class_name');
        descriptorPageForage.getItem('class_name').then(class_name => {
          if (class_name && this.$d.getClass(class_name)) {
            console.log('using cached class_name:', class_name);
            this.class_name = class_name;
          }
        });
      }
      this.$emit('onClassName', this.class_name);

      this.initRouteQuery();
    },
    initRouteQuery() {
      console.log('query params', this.$route.query);

      this.route_query_relationship_actions.forEach(relationship_action => {
        //TODO: works for only 1 relationship
        let relationship = relationship_action['relationship'];
        let direction = relationship_action['direction'];
        let inv_direction = this.$d.invertRelationshipDirection(direction);

        let relation_alias = this.$d.getRelationAlias(relationship, direction);

        let relation_id = this.$route.query[relation_alias];
        this.target_class_name = relationship[direction]['class_name'];

        console.log('initRouteQuery', relationship, direction, inv_direction, this.target_class_name);
        console.log(relation_alias);
        console.log(relation_id);

        this.index_cache_key = `_${_.snakeCase(this.target_class_name)}_${relation_id}`;

        console.log('index_cache_key', this.index_cache_key);

        // this.to_relation = null;
        this.has_to_relation = true;
        this.loading = true;

        this.predefined_filter_params = {
          filters: [
            {
              filter_type: 'relationship',
              existence: 'present',
              direction: direction,
              from_class_name: relationship['from']['class_name'],
              relationship_name: relationship['relationship_name'],
              filters: [{ filter_type: 'property', existence: 'present', property_key: 'id', equal: relation_id }],
            },
          ],
        };

        this.allow_predefined_filter = false;

        let params = {};
        params[this.$d.getRelationAlias(relationship)] = [{
          id: relation_id
        }]; //TODO get full details
        this.predefined_params = Object.assign({}, this.predefined_params, params)
        console.log('draft predefined_params', this.predefined_params);
        console.log('draft fixed_params_', this.fixed_params_);

        let read_cache_key = inv_direction + '_relation_' + this.target_class_name + '_' + relation_id;

        this.getFromCache(relationship, read_cache_key);
        this.error_message = null;
        this.success_message = null;

        this.$d.request(
          'read',
          this.target_class_name,
          {
            id: relation_id,
            with_childs: true,
            with_virtual_properties: true,
            index_withs: true,
          },
          class_data => {
            this.loaded_from_server = true;
            this.$d.setCache(read_cache_key, class_data);
            console.log('save cache', read_cache_key, class_data);

            this.setProcessedData(relationship, class_data);

            this.error_message = null; //makes sure no error messages from previous
          },
          (error_message, cancelled) => {
            console.error(error_message);

            if (error_message) {
              this.error_message = error_message;
            }

            if (cancelled) {
              //do nothign if cancelled
            } else if (!this.error_message) {
              this.error_message = this.$api.defaultErrorMessage;
            }
          },
          () => {
            this.loading = false;
          }
        );
      });
    },
    getFromCache(relationship, read_cache_key) {
      this.loaded_from_server = false;

      this.$d.getCache(read_cache_key, (class_data, cache_age) => {
        if (class_data) {
          if (cache_age >= 12 * 3600 * 1000) {
            console.log('cache too old, ignored, clear previous data', read_cache_key, class_data, cache_age);
            if (!this.loaded_from_server) {
              this.clearProcessedData(relationship);
            }
          } else {
            console.log('retrieved from cache', read_cache_key, class_data, cache_age);
            if (!this.loaded_from_server) {
              this.setProcessedData(relationship, class_data);
            }
          }
        } else {
          console.log('no cache, clear previous data', read_cache_key);
          if (!this.loaded_from_server) {
            this.clearProcessedData(relationship);
          }
        }
      });
    },
    setProcessedData(relationship, class_data) {
      this.to_relation = class_data;
      console.log(this.to_relation);

      let params = {};
      params[this.$d.getRelationAlias(relationship)] = [this.to_relation];
      this.predefined_params = Object.assign({}, this.predefined_params, params)
      
      console.log('real predefined_params', this.predefined_params);
      console.log('real fixed_params_', this.fixed_params_);

      // :fixed_params="{'dispenser_location_to_billing_account':[billing_account]}"
      // :stateIdentifier="'_billing_account_' + billing_account.id"
    },
    clearProcessedData(relationship) {
      this.to_relation = null;

      let params = {};
      params[this.$d.getRelationAlias(relationship)] = null;
      this.predefined_params = Object.assign({}, this.predefined_params, params)
    },
    cacheClassName(class_name) {
      console.log('cache class_name:', class_name);
      descriptorPageForage.setItem('class_name', class_name);
    },
  },
};
</script>

<style scoped></style>
